import React from 'react'
import styled from 'styled-components';

import Tile from "../Tile";


const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TilesList = ({ tilesData }) => (
  <List>
      {
        tilesData.map((tile) => (
          <Tile
            key={tile.id}
            text={tile.shortText}
            src={tile.imageItem.image.url}
            url={tile.url}
          />
        ))
      }
  </List>
);

export default TilesList;
