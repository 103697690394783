import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import TilesList from '../../../components/Tile/TilesList';


const Offers = ({ first }) => (
  <StaticQuery
    query={graphql`
      query Teasers {
        dysplan {
          teasers {
            id
            url
            shortText
            imageItem {
              alt
              title
              id
              image {
                url
                fileName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let tilesData = data.dysplan.teasers;
      if (first) {
        tilesData = data.dysplan.teasers.slice(0, first);
      }
      return (<TilesList tilesData={tilesData} />);
    }}
  />
);

Offers.propTypes = {
  // limit of teaser number
  first: PropTypes.number,
};

export default Offers;