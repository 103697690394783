import React from 'react';
import { Link } from 'gatsby';
import styled from "styled-components";

import { baseBlack, minBreakpoint, normalBreakpoint, mediumBreakpoint } from '../styles-settings';


const Root = styled.li`
  width: 100%;
  
  @media (min-width: ${`${minBreakpoint}px`}) {
    width: 50%;
  }
  
  @media (min-width:  ${`${normalBreakpoint}px`}) {
    width: 25%;
  }
  
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const BackgroundImage = styled.div`
  background: url(${(props) => props.src}) no-repeat center center;
  background-size: cover;
  position: relative;
  height: 15rem;

  @media (min-width:  ${`${mediumBreakpoint}px`}) {
    height: 20rem;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  padding: 1.5rem 0.5rem;
  background: rgba(255,255,255, 0.7);
  text-align: center;
`;

const OverlayDescription = styled.span`
  color: ${baseBlack};
`;


class Tile extends React.PureComponent {
  render() {
    const { text, src, url } = this.props;
    return (
      <Root >
        <Link to={url}>
          <BackgroundImage src={src}>
            <Overlay>
              <OverlayDescription>
                {text}
              </OverlayDescription>
            </Overlay>
          </BackgroundImage>
        </Link>
      </Root>
    );
  };
}

export default Tile;
